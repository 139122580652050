import gsap from 'gsap'

export default () => {
	// JS MQ
	const projectList = document.querySelector('.projects')
	if (!projectList) return

	let currentLayout = projectList.getAttribute('data-layout')

	const viewControl = document.querySelector('.view-control')
	if (viewControl) {
		const triggers = viewControl.querySelectorAll('a')
		triggers.forEach((trigger) => {
			trigger.addEventListener('click', (e) => {
				e.preventDefault()
				triggers.forEach((t) => t.classList.remove('active'))
				currentLayout = trigger.getAttribute('data-layout')
				trigger.classList.add('active')
				gsap.to(projectList, {
					opacity: 0,
					duration: 0.5,
					onComplete: () => {
						const view = trigger.getAttribute('data-layout')
						projectList.setAttribute('data-layout', view)
						gsap.to(projectList, {
							opacity: 1,
							duration: 0.5,
							delay: 0.5,
						})
					},
				})
			})
		})
	}

	const forceGrid = () => {
		if (mq.matches) {
			projectList.setAttribute('data-layout', 'grid')
			if (viewControl) {
				viewControl.classList.add('hidden')
			}
		} else {
			projectList.setAttribute('data-layout', currentLayout)
			if (viewControl) {
				viewControl.classList.remove('hidden')
			}
		}
	}

	const mq = window.matchMedia('(max-width: 800px)')
	forceGrid()
	mq.addEventListener('change', () => {
		forceGrid()
	})
}
