export default () => {
	const lightbox = document.querySelector('.lightbox')
	if (lightbox === null) return
	const lightboxImg = lightbox.querySelector('img')
	const lightboxBg = lightbox.querySelector('.lightbox-bg-overlay')
	const lightboxCaption = lightbox.querySelector('figcaption')
	const triggers = document.querySelectorAll('.lightbox-image')
	lightboxCaption.innerText = ''
	console.log(triggers)
	if (triggers.length > 0) {
		triggers.forEach((trigger) => {
			trigger.addEventListener('click', () => {
				const img = trigger.querySelector('img')
				const src = img.getAttribute('src')
				const cap = img.getAttribute('data-caption')

				lightboxImg.setAttribute('src', src)
				lightboxCaption.innerText = cap
				lightbox.classList.add('open')
			})
		})
	}
	lightboxBg.addEventListener('click', () => {
		lightbox.classList.remove('open')
	})
}
