import navigation from './modules/navigation'
import projectViews from './modules/project-views'
import lightboxes from './modules/lightboxes'
import Swiper from 'swiper'
import { Navigation, Pagination, FreeMode, Thumbs } from 'swiper/modules'
import projectModal from './modules/project-modal'

navigation()
projectViews()
lightboxes()
projectModal()

const teamSwipers = document.querySelectorAll('.team-swiper')
teamSwipers.forEach((swiper) => {
	const s = new Swiper(swiper, {
		modules: [Navigation],
		// loop: true,
		direction: 'horizontal',
		slidesPerView: 'auto',
		spaceBetween: 16,
		navigation: {
			nextEl: '.next',
			prevEl: '.prev',
		},
	})
})

const hamburgers = document.querySelectorAll('.hamburger')
const navigationTag = document.querySelector('.c12-custom-navigation')

// hamburger.addEventListener('click', () => {
//     hamburger.classList.toggle('is-active')
// })

window.addEventListener(
	'scroll',
	(e) => {
		document.documentElement.style.setProperty(
			'--scroll-y',
			`${window.scrollY}px`,
		)
		if (window.scrollY >= 300) {
			navigationTag.classList.add('sticky-active')
		} else {
			if (!document.body.classList.contains('menu-open')) {
				navigationTag.classList.remove('sticky-active')
			}
		}
	},
	{
		passive: true,
	},
)

// menus
const handleFixedBody = () => {
	let scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
	const bodyTag = document.querySelector('body')
	if (bodyTag.classList.contains('menu-open')) {
		bodyTag.style.position = 'fixed'
		bodyTag.style.top = `-${scrollY}`
	} else {
		scrollY = bodyTag.style.top
		bodyTag.style.position = 'relative'
		bodyTag.style.top = 0
		window.scrollTo(0, parseInt(scrollY || '0') * -1)
	}
}

const menuControl = () => {
	const mq = window.matchMedia('(min-width: 980px)')
	const menuBtns = document.querySelectorAll('.hamburger')
	const gradientOverlay = document.querySelector('.menu-gradient')
	const close = document.querySelector('.close')

	menuBtns.forEach((btn) => {
		btn.addEventListener('click', () => {
			// menuBtns.forEach((aBtn) => aBtn.classList.toggle('is-active'))
			// btn.classList.toggle('is-active')
			document.body.classList.toggle('menu-open')
			handleFixedBody()
		})
	})
	gradientOverlay.addEventListener('click', () => {
		document.body.classList.remove('menu-open')
		menuBtns.forEach((btn) => {
			btn.classList.remove('is-active')
		})
		handleFixedBody()
	})
	close.addEventListener('click', () => {
		document.body.classList.remove('menu-open')
		menuBtns.forEach((btn) => {
			btn.classList.remove('is-active')
		})
		handleFixedBody()
	})
	mq.addEventListener('change', () => {
		if (mq.matches) {
			menuBtns.forEach((aBtn) => aBtn.classList.remove('is-active'))
			document.body.classList.remove('menu-open')
		}
	})
}
menuControl()

const video = document.querySelector('.background-video video')

if (video) {
	const mq = window.matchMedia('(max-width: 768px)')
	mq.addEventListener('change', () => {
		if (mq.matches) {
			video.pause()
		} else {
			video.play()
		}
	})
}
