import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import { fromHTML } from './utils'

export default () => {
	const projectModal = document.querySelector('.c12-project-modal')
	if (projectModal === null) return

	let swiper = null
	let projectModalImg = projectModal.querySelector('img')
	const projectModalTitle = projectModal.querySelector('.title')
	const projectModalLocation = projectModal.querySelector('.location')
	const projectModalBg = projectModal.querySelector('.bg-overlay')
	const projectCloseBtn = projectModal.querySelector('.close')
	const triggers = document.querySelectorAll('.projects .project')

	if (triggers.length > 0) {
		triggers.forEach((trigger) => {
			const anchor = trigger.querySelector('a')
			anchor.addEventListener('click', (e) => {
				e.preventDefault()
				const g = trigger
					.querySelector('.image')
					.getAttribute('data-json')
					? JSON.parse(
							trigger
								.querySelector('.image')
								.getAttribute('data-json'),
						)
					: ''
				const content = trigger
					.querySelector('.content')
					.getAttribute('data-json')
					? JSON.parse(
							trigger
								.querySelector('.content')
								.getAttribute('data-json'),
						)
					: ''
				const project = {
					img: trigger.querySelector('img').getAttribute('src'),
					title: trigger.querySelector('h2').innerHTML,
					location: trigger.querySelector('p').innerHTML,
					gallery: g,
					content: content,
				}
				console.log(project)

				// const cap = img.getAttribute('data-caption')
				const projectModalImgContainer =
					projectModal.querySelector('.image')
				projectModalImgContainer.innerHTML = ''
				if (project.gallery !== '') {
					const htmlStr = `
                    <div class="swiper project-swiper">

                        <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <img src="${project.img}" alt="">
                        </div>
                            ${project.gallery.images
								.map((image) => {
									return `<div class="swiper-slide">
                                                <img src="${image}" alt="">
                                            </div>`
								})
								.join('')}
                        </div>

                        <div class="swiper-pagination"></div>
                        <div class="navigation">
                            <div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>
                        </div>
                    </div>
                    `
					const swiperHTML = fromHTML(htmlStr)
					projectModalImgContainer.append(swiperHTML)
					swiper = new Swiper('.project-swiper', {
						modules: [Navigation],
						slidesPerView: 1,
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
					})
				} else {
					projectModalImg = document.createElement('img')
					projectModalImg.setAttribute('src', project.img)
					projectModalImgContainer.append(projectModalImg)
				}

				projectModalTitle.innerHTML = project.title
				projectModalLocation.innerHTML = project.location

				const projectModalContentContainer =
					projectModal.querySelector('.contact .content')

				projectModalContentContainer.innerHTML = ''
				const contentStr = `
						<h2>${project.content.title_1} <span>${project.content.title_2}</span></h2>
						${project.content.description}
					`
				projectModalContentContainer.innerHTML = contentStr
				projectModal.classList.add('open')
			})
		})
	}
	projectModalBg.addEventListener('click', () => {
		projectModal.classList.remove('open')
		if (swiper !== null) {
			swiper.destroy()
			swiper = null
		}
	})
	projectCloseBtn.addEventListener('click', () => {
		projectModal.classList.remove('open')
		if (swiper !== null) {
			swiper.destroy()
			swiper = null
		}
	})
}
