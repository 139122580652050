export default () => {
	const mainHeader = document.querySelector('.c12-custom-navigation')
	if (!mainHeader) return
	if (window.scrollY > 150) {
		mainHeader.classList.add('scrolled')
	}
	window.addEventListener('scroll', () => {
		if (window.scrollY > 150) {
			mainHeader.classList.add('scrolled')
		} else {
			mainHeader.classList.remove('scrolled')
		}
	})
}
